import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [pageStats, setPageStats] = useState(null);
  const [error, setError] = useState(null);
  const [since, setSince] = useState(new Date());
  const [until, setUntil] = useState(new Date());
  const [period, setPeriod] = useState("day");

  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginState = () => {
      window.FB.getLoginStatus((response) => {
        statusChangeCallback(response);
      });
    };

    const statusChangeCallback = (response) => {
      if (response.status === "connected") {
        setAuth(true);
      } else {
        setAuth(false);
      }
    };

    checkLoginState();
  }, []);

  useEffect(() => {
    if (token && auth) {
      const fetchUserProfile = () => {
        window.FB.api("/me", { fields: "name,picture" }, (response) => {
          if (response && !response.error) {
            console.log("User profile fetched:", response);
            setUser({
              name: response.name,
              image: response.picture.data.url,
            });
          } else {
            console.error("Error fetching user profile:", response.error);
          }
        });
      };
      fetchUserProfile();

    
      window.FB.api("/me/accounts", (response) => {
        if (response && !response.error) {
          console.log("User's pages fetched:", response);
          setPages(response.data);
        } else {
          console.error("Error fetching user's pages:", response.error);
        }
      });
    }
  }, [token, auth]);

  const handlePageSelect = (pageId) => {
    if (until < since) {
      setError("The 'until' date cannot be earlier than the 'since' date.");
      return;
    }

    setSelectedPage(pageId);
    setPageStats(null);
    setError(null);

    const selectedPageData = pages.find((page) => page.id === pageId);
    const pageAccessToken = selectedPageData.access_token;

    const unixSince = Math.floor(since.getTime() / 1000);
    const unixUntil = Math.floor(until.getTime() / 1000);

    console.log("Fetching stats for page ID:", pageId);
    console.log("Using Page Access Token:", pageAccessToken);
    console.log("Since date (Unix):", unixSince);
    console.log("Until date (Unix):", unixUntil);
    console.log("Period:", period);

    
    window.FB.api(
      `/${pageId}/insights/`,
      {
        access_token: pageAccessToken,
        metric:
          "page_actions_post_reactions_like_total,page_actions_post_reactions_love_total,page_actions_post_reactions_wow_total,page_actions_post_reactions_haha_total,page_actions_post_reactions_sorry_total,page_actions_post_reactions_anger_total,page_impressions,page_post_engagements,page_fans",
        since: unixSince,
        until: unixUntil,
        period: period,
      },
      (response) => {
        if (response && !response.error) {
          console.log("Page stats fetched:", response);
          if (response.data && response.data.length > 0) {
            const metrics = response.data.reduce((acc, metric) => {
              acc[metric.name] = metric.values[0].value;
              return acc;
            }, {});

            setPageStats({
              totalFollowers: metrics.page_fans,
              totalImpressions: metrics.page_impressions,
              totalReactions:
                metrics.page_actions_post_reactions_like_total +
                metrics.page_actions_post_reactions_love_total +
                metrics.page_actions_post_reactions_wow_total +
                metrics.page_actions_post_reactions_haha_total +
                metrics.page_actions_post_reactions_sorry_total +
                metrics.page_actions_post_reactions_anger_total,
              totalEngagement: metrics.page_post_engagements,
            });
          } else {
            setError("No insights found");
          }
        } else {
          console.error("Error fetching page stats:", response.error);
          setError("Error fetching page stats");
        }
      }
    );
  };

  const handleLogout = () => {
    window.FB.logout(() => {
      dispatch(logout());
      navigate("/login");
    });
  };

  useEffect(() => {
    if (auth && token && selectedPage && selectedPage.length > 0) {
      handlePageSelect(selectedPage);
    }
  }, [selectedPage, until, since, period]);

  if (!user) {
    return "loading...";
  }

  console.log(pageStats);

  return (
    <main className="main h-full flex justify-center items-center">
      <div className="container max-w-md p-4 mx-auto bg-white rounded shadow-md">
        <div className="profile-5050-fields profile-details-container grid grid-cols-1 gap-4">
          <div className="top-wrapper">
            <div className="shadow-card personal-details-card bg-white p-4 rounded shadow-md">
              <div className="title-area">
                <h3 className="text-lg font-bold mb-2">Personal Details</h3>
              </div>
              <div className="profile-split-flex flex flex-wrap justify-center">
                <div className="profile-image w-24 h-24 rounded-full overflow-hidden mr-4">
                  <img
                    src={user.image}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="profile-column flex-1">
                  <div className="info-contents">
                    <p className="text-gray-600">
                      <i className="ri-user-smile-line text-lg mr-2"></i>{" "}
                      {user.name}
                    </p>
                  </div>
                </div>
                <button className="btn btn-primary ml-4" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Select box for pages */}
        <div className="mt-4">
          <label htmlFor="pageSelect" className="block text-gray-600 mb-2">
            Select a Page:
          </label>
          <select
            id="pageSelect"
            className="form-control block w-full p-2 pl-10 text-sm text-gray-700"
            onChange={(e) => handlePageSelect(e.target.value)}
          >
            <option value="">Select a Page</option>
            {pages.map((page) => (
              <option key={page.id} value={page.id}>
                {page.name}
              </option>
            ))}
          </select>
        </div>

        {/* Date pickers for since and until */}
        <div className="mt-4 flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-0 md:mr-4">
            <label htmlFor="sinceDate" className="block text-gray-600 mb-2">
              Since:
            </label>
            <DatePicker
              id="sinceDate"
              selected={since}
              onChange={(date) => setSince(date)}
              className="form-control block w-full p-2 text-sm text-gray-700"
            />
          </div>
          <div>
            <label htmlFor="untilDate" className="block text-gray-600 mb-2">
              Until:
            </label>
            <DatePicker
              id="untilDate"
              selected={until}
              onChange={(date) => setUntil(date)}
              className="form-control block w-full p-2 text-sm text-gray-700"
            />
          </div>
        </div>

        {/* Select box for period */}
        <div className="mt-4">
          <label htmlFor="periodSelect" className="block text-gray-600 mb-2">
            Select Period:
          </label>
          <select
            id="periodSelect"
            className="form-control block w-full p-2 pl-10 text-sm text-gray-700"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="days_28">28 days</option>
            
          </select>
        </div>

        {/* Display page stats */}
        {selectedPage && pageStats && (
          <div className="mt-4">
            <h4 className="text-lg font-bold mb-2">
              Page Statistics for {selectedPage}
            </h4>
            <div className="row flex flex-wrap justify-center">
              <div className="col-md-3 w-full ">
                <div className="card bg-white p-4 rounded shadow-md">
                  <div className="card-body">
                    <h5 className="card-title text-lg font-bold mb-2">
                      Total Followers / Fans
                    </h5>
                    <p className="card-text text-gray-600">
                      {pageStats.totalFollowers ? pageStats.totalFollowers : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 w-full ">
                <div className="card bg-white p-4 rounded shadow-md">
                  <div className="card-body">
                    <h5 className="card-title text-lg font-bold mb-2">
                      Total Impressions
                    </h5>
                    <p className="card-text text-gray-600">
                      {pageStats.totalImpressions}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 w-full ">
                <div className="card bg-white p-4 rounded shadow-md">
                  <div className="card-body">
                    <h5 className="card-title text-lg font-bold mb-2">
                      Total Reactions
                    </h5>
                    <p className="card-text text-gray-600">
                      {pageStats.totalReactions}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 w-full ">
                <div className="card bg-white p-4 rounded shadow-md">
                  <div className="card-body">
                    <h5 className="card-title text-lg font-bold mb-2">
                      Total Engagement
                    </h5>
                    <p className="card-text text-gray-600">
                      {pageStats.totalEngagement}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedPage && !pageStats && error && (
          <div className="mt-4">
            <h4 className="text-lg font-bold mb-2">Error</h4>
            <p className="text-red-600">{error}</p>
          </div>
        )}
      </div>
    </main>
  );
};
