import React, { useEffect } from "react";
import {
  logout,
  setDeviceId,
  setToken,
  setUser,
} from "../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("user_token");
    if (token) {
      const checkLoginState = () => {
        window.FB.getLoginStatus((response) => {
          statusChangeCallback(response);
        });
      };

      const statusChangeCallback = (response) => {
        if (response.status === "connected") {
          // User is logged into your app and
          dispatch(setUser(response.authResponse));
          dispatch(setToken(response.authResponse.accessToken));

          console.log("Logged in:", response.authResponse);
          navigate("/");
          // You can also fetch user information here using FB.api()
        } else {
          // The person is not logged into your app or we are unable to tell
          console.log("Not logged in");
          dispatch(logout());
          // dispatch(logout());
        }
      };

      // Check login state when the component mounts
      checkLoginState();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("user_token");
    const user = localStorage.getItem("user_data");
    if (token) {
      dispatch(setToken(token));
      dispatch(setUser(JSON.parse(user)));
      const currentTime = Math.floor(Date.now() / 1000);
      const parseUser = JSON.parse(user);
      console.log(parseUser.data_access_expiration_time, currentTime);
      if (
        parseUser.data_access_expiration_time &&
        currentTime > parseUser.data_access_expiration_time
      ) {
        dispatch(logout());
      }
    }
  }, []);

  return <div>{children}</div>;
};
