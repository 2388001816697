import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const FacebookLoginButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const fetchUserProfile = () => {
            window.FB.api(
              "/me",
              {
                fields:
                  "name,picture,ads_management,ads_read,business_management,catalog_management,commerce_account_manage_orders,commerce_account_read_orders,commerce_account_read_reports,commerce_account_read_settings,email,instagram_basic,instagram_branded_content_ads_brand,instagram_branded_content_brand,instagram_branded_content_creator,instagram_content_publish,instagram_manage_comments,instagram_manage_events,instagram_manage_insights,instagram_manage_messages,instagram_shopping_tag_products,leads_retrieval,manage_fundraisers,page_events,pages_manage_ads,pages_show_list,manage_pages_cta,pages_manage_engagement,pages_manage_instant_articles,pages_manage_metadata,pages_manage_posts,pages_messaging,pages_read_engagement,pages_read_user_content,publish_video,read_insights,read_page_mailboxes,whatsapp_business_management,whatsapp_business_messaging",
              },
              (response) => {
                if (response && !response.error) {
                  console.log(response);
                }
              }
            );
          };
          dispatch(setUser(response.authResponse));
          dispatch(setToken(response.authResponse.accessToken));
          navigate("/");
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: "email, public_profile, pages_show_list, pages_read_engagement",
      }
    ); // Adjust scope as per your app's requirements

    // You may need additional permissions based on your app's functionality
  };

  return (
    <div>
      <button className="loginBtn loginBtn--facebook" onClick={handleLogin}>
        Login with Facebook
      </button>
    </div>
  );
};

export default FacebookLoginButton;
