import { createSlice } from "@reduxjs/toolkit";




const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    deviceId: null,
    error: null,
    message: null,
    loading: "idle",
    updateUserLoading: "idle"
};

export const setSession = (token) => {
    if (token) {
        localStorage.setItem("user_token", token);
    } else {
        localStorage.removeItem("user_token");
    }
};


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            const data = action.payload;
            state.isAuthenticated = true;
            state.user = data;
            localStorage.setItem("user_data", JSON.stringify(data));
        },
        setToken: (state, action) => {
            const token = action.payload;
            state.token = token;
            setSession(token)
        },
        logout: (state) => {
            setSession(null);
            localStorage.removeItem("user_data");
            state.isAuthenticated = false;
            state.user = null;
            window.location.href = "/";

        },
        setDeviceId: (state, action) => {
            const id = action.payload;
            state.deviceId = id;

        },
    },

});

export const { setUser, logout, setToken,setDeviceId } = authSlice.actions;

export default authSlice.reducer;