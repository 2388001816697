import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthProvider } from './configs/AuthProvides';
import { LoginPage } from './pages/LoginPage';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Home } from './pages/Home';

function AppContent() {
  const location = useLocation();
  const { loading } = useSelector((state) => state.loading);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(()=>{
    if(!token){
      navigate("/login")
    }else{
      navigate("/")
    }
  },[token])

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </AuthProvider>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
