import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/auth.css";
import FacebookLoginButton from "../components/FaceBookLoginButton";

export const LoginPage = () => {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");
  const { loading } = useSelector((state) => state.loading);
  const [error, setError] = useState(null);


  

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="center-card">
          <p>Welcome to the web app</p>
        </div>
      </div>
      <div className="right-side">
        <div className="shadow-card width-80">
          <div className="form-contents">
            <Link to="/" className="logo-div">
              Mojo Test Assessment
            </Link>
            {error && (
              <div className="error-message middle-text w-full">{error}</div>
            )}
            <div className="title-contents">
              <h2>Login</h2>
              <p>Login into your account.</p>
            </div>
            <FacebookLoginButton />
          </div>
          
        </div>
      </div>
    </div>
  );
};
